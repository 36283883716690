<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-16' 
    :style='minHeightStyle'>
    <div
      class='background-style text-white py-4 px-4 lg:py-16 lg:px-8 flex-shrink-0'
      :style='`${sidebarBgStyle}`'>
      <ul class='text-xs sidebar-navigation-links flex flex-col lg:sticky lg:top-24'>
        <li v-for='(sidebarLink, index) in sidebarLinks'
          :key='`sidebar-link-${sidebarLink.title}-${index}`'
          class='mb-2 lg:mb-4'>
          <router-link v-if='sidebarLink.actionType==="open_internal_route"'
            :to='{ name: sidebarLink.link }'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            {{ sidebarLink.title }}
          </router-link>
          <a v-else-if='sidebarLink.actionType==="open_external_link"'
            :href='sidebarLink.link'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            {{ sidebarLink.title }}
          </a>
        </li>
      </ul>
    </div>
    <div class='px-4 lg:px-0 flex-grow relative'>
      <div class='py-8 lg:py-16'>
        <div class='flex flex-row gap-x-8 mb-4 lg:mb-8'>
          <h1 class='text-3xl font-bold'>ID(E-mail) 찾기</h1>
        </div>
        <div class='mb-6 w-full lg:w-96'>
          <label 
            class='uppercase text-sm text-gray-700'>
            이름
          </label>
          <input
            v-model='name'
            placeholder='이름'
            class='border py-1 px-2 rounded block w-full'>
        </div>
        <div class='mb-6 w-full lg:w-96'>
          <label 
            class='uppercase text-sm text-gray-700'>
            전화번호
          </label>
          <div>
            010 - 
            <input
              v-model='phoneNumberMiddleFourDigits'
              maxlength='4'
              class='border py-1 px-2 rounded w-20 text-center'
              @input='allowOnlyNumberMiddleFour($event)'> - 
            <input
              v-model='phoneNumberLastFourDigits'
              maxlength='4'
              class='border py-1 px-2 rounded w-20 text-center'
              @input='allowOnlyNumberLastFour($event)'>
          </div>
        </div>
        <button
          class='mb-6 w-full lg:w-96 border py-3 text-center text-white text-sm uppercase bg-gray-900 block rounded-md'
          :class='disabledFindEventIdButtonClass'
          :disabled='disabledFindEventIdButton'
          @click='findEventLoginId'>
          ID(E-mail) 찾기
        </button>
        <div class='bg-gray-100 p-8 font-semibold'>
          {{ findLoginIdBoxText }}
          <ul class='list-disc list-inside mt-2'>
            <div v-for='(id, index) in  eventLoginIds'
              :key='`${id}-${index}`'>
              <li class='mb-1 font-normal'>{{ id }}</li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'FindLoginId',
  components: {
  },
  data () {
    return {
      name: '',
      phoneNumberMiddleFourDigits: '',
      phoneNumberLastFourDigits: '',
      eventLoginIds: null,
    }
  },
  computed: {
    ...mapGetters('events',[
      'eventMainThemeColor',
    ]),
    sidebarBgStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    isValidPhoneNumber () {
      return this.phoneNumberMiddleFourDigits.length >= 3 && this.phoneNumberLastFourDigits.length >= 3 
    },
    disabledFindEventIdButton () {
      return !this.name ||
             !this.isValidPhoneNumber
    },
    disabledFindEventIdButtonClass () {
      return this.disabledFindEventIdButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    findEventLoginIdParams () {
      return {
        name: this.name,
        phoneNumber: `010-${this.phoneNumberMiddleFourDigits}-${this.phoneNumberLastFourDigits}`
      }
    },
    sidebarLinks () {
      return [
        {
          'link': 'Login',
          'title': '로그인하러 가기',
          'actionType': 'open_internal_route'
        },
        {
          'link': 'FindLoginId',
          'title': 'ID(E-mail) 찾기',
          'actionType': 'open_internal_route'
        },
        {
          'link': 'ChangeLoginPassword',
          'title': 'Password 재설정',
          'actionType': 'open_internal_route'
        },
      ]
    },
    findLoginIdBoxText () {
      if (this.eventLoginIds) {
        if (this.eventLoginIds.length) {
          return '등록된 ID는 아래와 같습니다.'
        } else {
          return '등록된 ID를 찾을 수 없습니다.'
        }
      } else {
        return '등록된 ID를 찾기 위해 이름과 전화번호를 입력해주십시오.'
      }
    },
  },
  methods: {
    ...mapActions('users', [
      'getEventLoginId'
    ]),
    allowOnlyNumberMiddleFour (event) {
      this.phoneNumberMiddleFourDigits = event.target.value.replace(/[^0-9]/g, '')
    },
    allowOnlyNumberLastFour (event) {
      this.phoneNumberLastFourDigits = event.target.value.replace(/[^0-9]/g, '')
    },
    findEventLoginId () {
      this.getEventLoginId(this.findEventLoginIdParams).then(resp => {
        this.eventLoginIds = resp
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.background-style {
  background: transparent; 
  width: 100%;
}

.sidebar-navigation-links .router-link-active {
  @apply font-bold;
}

@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}    
</style>
